import {
  FormContextData,
  FormContextType,
  formIsCompleted,
  PatientTimelineResponse,
  VisionHasPolicePresenceRequested,
  VisionPolicePresenceRequestedActionType,
} from "@aspire/common";
import {
  Box,
  Card,
  Chip,
  CircularProgress,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {
  OfficerRequestStatuses,
  PolicePresenceReason,
  ShareableIncidentDataV1Response,
} from "@thalamos/common";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { v4 } from "uuid";
import { api, apiHooks } from "~/api.js";
import {
  Banner,
  BannerList,
  Button,
  Dropdown,
  MenuFlyout,
  MenuOptionsType,
  PopupDialog,
  PopupDialogTitle,
  renderErrorToast,
  renderSuccessToast,
} from "~/components/design-system/index.js";
import { PatientBanner } from "~/components/layout/index.js";
import { LoggedInUserContext } from "~/Contexts.js";
import { routeFns } from "~/routes.js";
import { logErrorToDatadog } from "~/tracing.js";
import { triggerDownload, ukLocalFormatDateTime } from "~/util.js";
import { FormContextPdfViewer } from "../helpers/FormContextPdfViewer.js";
import { ParticipantsList } from "../helpers/ParticipantsList.js";

type OpenRequestPolicePresencePopup = "yes" | "no" | null;

function SynchroniseIncidentDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation();

  return (
    <PopupDialog open={open} onClose={onClose} title="Synchronise Incident">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 4,
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        {t("pages.policeIncidentFormPage.synchroniseLoadingMessage")}
        <CircularProgress />
      </Box>
    </PopupDialog>
  );
}

function policeIncidentLogic(formContext: FormContextData) {
  const myWorkItem = formContext.activeTeamworkWorkItem;

  const monitoringForm = myWorkItem
    ? myWorkItem.forms.find((f) => f.formTemplate.id === "mha-136-monitoring")
    : null;

  const monitoringFormInProgress = monitoringForm?.status === "in-progress";
  const monitoringFormCompleted = ["finalised", "complete"].includes(
    monitoringForm?.status ?? "",
  );

  const userDraftId = monitoringFormInProgress && monitoringForm.formDraftId;

  const policeForm = formContext.forms.find(
    (f) => f.formTemplate.id === "mha-136",
  )!;
  return {
    monitoringForm,
    monitoringFormInProgress,
    monitoringFormCompleted,
    userDraftId,
    policeForm,
  };
}

export function PoliceIncidentFormPage({
  reloadFormContext,
  formContext,
  patientTimeline,
  reloadPatientTimeline,
}: {
  formContext: FormContextData;
  reloadFormContext: () => void;
  patientTimeline: PatientTimelineResponse | null;
  reloadPatientTimeline: () => void;
}) {
  const [loading, setLoading] = useState(false);
  const [incidentId, setIncidentId] = useState<string | null>(null);

  async function requestIncident() {
    setLoading(true);
    const response = await api.vision.synchroniseIncidentForFormContext(
      formContext.id,
    );

    if (response.status === 200) {
      setIncidentId(response.data.incidentId);
      setLoading(false);
    } else {
      renderErrorToast({ message: "Failed to synchronise incident" });
      setLoading(false);
    }
  }

  React.useEffect(() => {
    requestIncident();
  }, []);

  return !loading && incidentId ? (
    <PoliceIncidentFormPageInner
      reloadFormContext={reloadFormContext}
      formContext={formContext}
      patientTimeline={patientTimeline}
      reloadPatientTimeline={reloadPatientTimeline}
      incidentId={incidentId}
    />
  ) : (
    <CircularProgress />
  );
}

export function PoliceIncidentFormPageInner({
  reloadFormContext,
  formContext,
  patientTimeline,
  reloadPatientTimeline,
  incidentId,
}: {
  formContext: FormContextData;
  reloadFormContext: () => void;
  patientTimeline: PatientTimelineResponse | null;
  reloadPatientTimeline: () => void;
  incidentId: string;
}) {
  const user = React.useContext(LoggedInUserContext);
  const policeIncidentCardRef = React.useRef(null);
  const [policeIncidentActionsIsOpen, setPoliceIncidentActionsIsOpen] =
    React.useState(false);

  const monitoringFormCardRef = React.useRef(null);
  const [monitoringFormActionsIsOpen, setMonitoringFormActionsIsOpen] =
    React.useState(false);

  const {
    policeForm,
    monitoringForm,
    monitoringFormCompleted,
    monitoringFormInProgress,
    userDraftId,
  } = policeIncidentLogic(formContext);

  const [isCreatingForm, setIsCreatingForm] = useState(false);
  const navigate = useNavigate();

  const launchForm = React.useCallback(async () => {
    if (!isCreatingForm) {
      setIsCreatingForm(true);
      try {
        const formDraftId = v4();
        const formId = monitoringForm?.id ?? v4();

        // Ensure we have a monitoring form
        if (!monitoringForm) {
          const createFormResult = await api.forms.create(formId, {
            formContext: { mode: "existing", id: formContext.id },
            formTemplate: { id: "mha-136-monitoring", version: "1.0" },
            patientId: formContext.patientId,
          });
          if (createFormResult.status !== 204) {
            renderErrorToast({ message: "Failed to create monitoring form" });
            return;
          }
        }

        const draftCreateResult = await api.drafts.create(formDraftId, {
          formId,
        });

        if (draftCreateResult.status === 201) {
          navigate(
            routeFns.formDraftsComplete(formDraftId, formContext.patientId),
            {
              replace: true,
            },
          );
        }
      } catch (e) {
        if (e instanceof Error) {
          logErrorToDatadog(e);
        }
        renderErrorToast({ message: "Failed to create monitoring form" });
      } finally {
        setIsCreatingForm(false);
      }
    }
  }, [formContext, isCreatingForm]);

  const [syncDialogOpen, setSyncDialogOpen] = useState(true);
  const [pdfViewFormId, setPdfViewFormId] = useState<null | string>(null);

  const [{ loading, data, response }] =
    apiHooks.vision.synchroniseIncidentForFormContext(
      incidentId,
      formContext.id,
    );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const officerRequest: ShareableIncidentDataV1Response | undefined | null =
    data;

  const [openRequestPolicePresencePopup, setOpenRequestPolicePresencePopup] =
    useState<OpenRequestPolicePresencePopup>(null);

  const theme = useTheme();
  const { t } = useTranslation();

  const nhsNumber = patientTimeline?.patient.nhsNumber;

  React.useEffect(() => {
    if (loading || !response) return;

    switch (response.status) {
      case 204:
      case 200:
        setSyncDialogOpen(false);
        // TODO: store viewed versions server/client side to prevent this
        // reopening all the time?
        if (!monitoringFormCompleted) {
          setPdfViewFormId(policeForm.id);
        }
        reloadFormContext();
        break;
      default:
        renderErrorToast({ message: "Failed to synchronise incident" });
    }
  }, [loading, response]);

  const renderOfficerStatus = (status: string) => {
    const statusMapping: Record<
      ShareableIncidentDataV1Response["officerRequest"]["status"],
      { label: string; body?: string; color: string }
    > = {
      requested: {
        label: t("pages.policeIncidentFormPage.statusChips.requested.label"),
        body: t("pages.policeIncidentFormPage.statusChips.requested.body"),
        color: theme.palette.warning.main,
      },
      accepted: {
        label: t("pages.policeIncidentFormPage.statusChips.accepted.label"),
        body: t("pages.policeIncidentFormPage.statusChips.accepted.body"),
        color: theme.palette.success.main,
      },
      rejected: {
        label: t("pages.policeIncidentFormPage.statusChips.rejected.label"),
        body: t("pages.policeIncidentFormPage.statusChips.rejected.body"),
        color: theme.palette.error.main,
      },
      notRequested: {
        label: t("pages.policeIncidentFormPage.statusChips.notRequested.label"),
        body: undefined,
        color: theme.palette.grey[500],
      },
      decisionNeeded: {
        label: t(
          "pages.policeIncidentFormPage.statusChips.decisionNeeded.label",
        ),
        body: undefined,
        color: theme.palette.info.main,
      },
    };

    const statusInfo =
      statusMapping[
        status as ShareableIncidentDataV1Response["officerRequest"]["status"]
      ] || statusMapping["decisionNeeded"];

    return (
      <>
        <Chip
          label={statusInfo.label}
          sx={{ backgroundColor: statusInfo.color, color: "white" }}
        />
        {statusInfo.body && officerRequest?.officerDetails?.name && (
          <Typography variant="body1">
            {officerRequest.officerRequest.updatedAt
              ? ukLocalFormatDateTime(
                  new Date(
                    officerRequest.officerRequest.updatedAt,
                  ).toISOString(),
                )
              : null}{" "}
            - {statusInfo.body}
          </Typography>
        )}
      </>
    );
  };

  const officerRequestStatus = officerRequest?.officerRequest?.status;

  const disableOfficerRequestButtons =
    officerRequestStatus !== OfficerRequestStatuses.decisionNeeded;

  const generateMenuOptions = (formId: string, formType: FormContextType) => {
    return [
      {
        icon: "view",
        onClick: async () => {
          setPdfViewFormId(formId);
        },
        name: "View PDF",
        disabled: false,
      },
      {
        icon: "download",
        onClick: async () => {
          const result = await api.forms.getPdf(
            formId,
            formContext.type === formType,
          );
          if (result.status === 200) {
            const dataUri = `data:application/pdf;base64,${result.data}`;
            triggerDownload(dataUri, "form.pdf");
          } else {
            renderErrorToast({
              message: t("pages.policeIncidentFormPage.failedPdfDownload"),
            });
          }
        },
        name: "Download PDF",
        disabled: false,
      },
    ];
  };

  const policeIncidentOptions: MenuOptionsType[] = generateMenuOptions(
    policeForm.id,
    "police-incident",
  );

  const monitoringFormMenuOptions: MenuOptionsType[] = monitoringForm
    ? generateMenuOptions(monitoringForm.id, "police-incident")
    : [];

  return (
    <>
      <Box
        data-testid="form-context:police-incident"
        data-formcontextid={formContext.id}
      >
        {syncDialogOpen && (
          <SynchroniseIncidentDialog
            open={syncDialogOpen}
            onClose={() => {
              setSyncDialogOpen(false);
              setPdfViewFormId(policeForm.id);
            }}
          />
        )}
        {pdfViewFormId && (
          <FormContextPdfViewer
            inModal={true}
            forms={formContext.forms
              .filter(
                (f) =>
                  formIsCompleted(f) ||
                  f.versions[f.latestVersion - 1].signatures.length > 0,
              )
              .sort((f1, f2) => (f1.updated < f2.updated ? 1 : -1))}
            setFormId={setPdfViewFormId}
            formId={pdfViewFormId}
          />
        )}

        <Box sx={{ mb: 3 }}>
          {patientTimeline && (
            <PatientBanner
              patient={formContext.patient}
              nhsNumber={nhsNumber ?? undefined}
              disableSticky
              isHorizontalLineHidden
              patientTimeline={patientTimeline}
              reloadPatientTimeline={reloadPatientTimeline}
            />
          )}
        </Box>

        <Stack sx={{ display: "flex" }}>
          <Card
            sx={{
              width: "100%",
              padding: "2rem",
              mb: "2rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5" gutterBottom>
              {t("pages.policeIncidentFormPage.formTitles.policeIncident")}
            </Typography>
            <Box>
              {!!policeIncidentOptions.length && (
                <Box
                  sx={{
                    marginTop: {
                      xs: 0,
                      md: (theme) => theme.spacing(2),
                    },
                  }}
                  ref={policeIncidentCardRef}
                  onClick={() =>
                    setPoliceIncidentActionsIsOpen(!policeIncidentActionsIsOpen)
                  }
                >
                  <MenuFlyout
                    cardRef={policeIncidentCardRef}
                    options={policeIncidentOptions}
                    isOpen={policeIncidentActionsIsOpen}
                    onClose={() => setPoliceIncidentActionsIsOpen(false)}
                  />
                </Box>
              )}
            </Box>
          </Card>

          <Card sx={{ width: "100%", padding: "2rem", mb: "2rem" }}>
            <Typography variant="h5" gutterBottom>
              {t(
                "pages.policeIncidentFormPage.formTitles.requestPolicePresence",
              )}
            </Typography>
            <Typography variant="caption" gutterBottom>
              {t(
                "pages.policeIncidentFormPage.formTitles.ongoingPolicePresence",
              )}
            </Typography>
            <Box sx={{ display: "flex", gap: "2rem", mt: "0.5rem" }}>
              <Button
                label={t("common.no")}
                variant="outlined"
                disabled={disableOfficerRequestButtons}
                color="inherit"
                onClick={() => setOpenRequestPolicePresencePopup("no")}
              />
              <Button
                label={t("common.yes")}
                variant="outlined"
                disabled={disableOfficerRequestButtons}
                color="inherit"
                onClick={() => setOpenRequestPolicePresencePopup("yes")}
              />
            </Box>
            {officerRequestStatus && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  mt: "1rem",
                }}
              >
                {renderOfficerStatus(officerRequestStatus)}
              </Box>
            )}
          </Card>

          <Card
            sx={{
              width: "100%",
              padding: "2rem",
              mb: "2rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5" gutterBottom>
              {t("pages.policeIncidentFormPage.formTitles.sectionOutcome")}
            </Typography>
            <Box>
              {!monitoringFormCompleted && !userDraftId && (
                <Button
                  label="Start"
                  onClick={() => {
                    launchForm();
                  }}
                />
              )}
              {monitoringFormInProgress && userDraftId && (
                <Button
                  label="Continue"
                  onClick={() => {
                    navigate(
                      routeFns.formDraftsComplete(
                        userDraftId!,
                        formContext.patientId,
                      ),
                    );
                  }}
                />
              )}
              {monitoringForm &&
                monitoringFormCompleted &&
                !!monitoringFormMenuOptions.length && (
                  <Box
                    sx={{
                      marginTop: {
                        xs: 0,
                        md: (theme) => theme.spacing(2),
                      },
                    }}
                    ref={monitoringFormCardRef}
                    onClick={() =>
                      setMonitoringFormActionsIsOpen(
                        !monitoringFormActionsIsOpen,
                      )
                    }
                  >
                    <MenuFlyout
                      options={monitoringFormMenuOptions}
                      cardRef={monitoringFormCardRef}
                      isOpen={monitoringFormActionsIsOpen}
                      onClose={() => setMonitoringFormActionsIsOpen(false)}
                    />
                  </Box>
                )}
            </Box>
          </Card>
        </Stack>

        <ParticipantsList formContext={formContext} />
      </Box>
      {openRequestPolicePresencePopup === "yes" && (
        <RequestPolicePresencePopupDialog
          isSubmitting={isSubmitting}
          setOpenRequestPolicePresencePopup={setOpenRequestPolicePresencePopup}
          posName={
            officerRequest?.activePlaceOfSafety?.name ?? t("common.unknown")
          }
          requestPolicePresenceCall={async ({
            reasonValue,
            reasonLabel,
            furtherReasoning,
          }) => {
            setIsSubmitting(true);
            if (!formContext.id) return;

            const requestData: VisionHasPolicePresenceRequested = {
              hasPolicePresenceBeenRequestedLabel: t(
                "pages.policeIncidentFormPage.ongoingPolicePresenceDialog.hasPolicePresenceBeenRequested",
              ),
              hasPolicePresenceBeenRequestedLabelValue: "yes",
              type: VisionPolicePresenceRequestedActionType.requestPolicePresence,
              reasonValue,
              reasonLabel,
              furtherReasoning,
            };

            const result = await api.vision.requestPolicePresence(
              formContext.id,
              requestData,
            );

            if (result.status === 204) {
              setIsSubmitting(false);
              setOpenRequestPolicePresencePopup(null);
              reloadFormContext();
              renderSuccessToast({
                message: t(
                  "pages.policeIncidentFormPage.toastNotifications.policePresenceRequested",
                ),
              });
            } else {
              // @ts-expect-error - data is "unknown"
              const errorMessage = result.data?.reason || "Unknown error";
              renderErrorToast({ message: errorMessage });
              setIsSubmitting(false);
            }
          }}
        />
      )}
      {openRequestPolicePresencePopup === "no" && (
        <NotPolicePresencePopupDialog
          setOpenRequestPolicePresencePopup={setOpenRequestPolicePresencePopup}
          requestPolicePresenceCall={async () => {
            setIsSubmitting(true);
            if (!formContext.id) return;
            const result = await api.vision.requestPolicePresence(
              formContext.id,
              {
                type: VisionPolicePresenceRequestedActionType.notRequestPolicePresence,
                hasPolicePresenceBeenRequestedLabel: t(
                  "pages.policeIncidentFormPage.ongoingPolicePresenceDialog.hasPolicePresenceBeenRequested",
                ),
                hasPolicePresenceBeenRequestedLabelValue: "no",
              },
            );
            if (result.status === 204) {
              setIsSubmitting(false);
              setOpenRequestPolicePresencePopup(null);
              reloadFormContext();
              renderSuccessToast({
                message: t(
                  "pages.policeIncidentFormPage.toastNotifications.policePresenceNotRequested",
                ),
              });
            } else {
              // @ts-expect-error - data is "unknown"
              const errorMessage = result.data?.reason || "Unknown error";
              renderErrorToast({ message: errorMessage });
              setIsSubmitting(false);
            }
          }}
        />
      )}
    </>
  );
}

function NotPolicePresencePopupDialog({
  setOpenRequestPolicePresencePopup,
  requestPolicePresenceCall,
}: {
  setOpenRequestPolicePresencePopup: (
    value: OpenRequestPolicePresencePopup,
  ) => void;
  requestPolicePresenceCall: () => void;
}) {
  const { t } = useTranslation();
  return (
    <PopupDialog
      open={true}
      onClose={() => setOpenRequestPolicePresencePopup(null)}
    >
      <PopupDialogTitle
        titleText={t(
          "pages.policeIncidentFormPage.notPoliceRequiredDialog.title",
        )}
        closeDialog={() => setOpenRequestPolicePresencePopup(null)}
      />
      <Box
        sx={{
          display: "flex",
          p: 2,
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        <Typography>
          {t("pages.policeIncidentFormPage.notPoliceRequiredDialog.response")}
        </Typography>
        <Typography>
          {t(
            "pages.policeIncidentFormPage.notPoliceRequiredDialog.policeConfirm",
          )}
        </Typography>
        <Box
          sx={{
            marginTop: "2em",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button
            label="Close"
            testId="close-button"
            onClick={() => setOpenRequestPolicePresencePopup(null)}
            variant="outlined"
          />
          <Button
            label="Confirm"
            testId="confirm-button"
            onClick={() => requestPolicePresenceCall()}
          />
        </Box>
      </Box>
    </PopupDialog>
  );
}

function RequestPolicePresencePopupDialog({
  setOpenRequestPolicePresencePopup,
  requestPolicePresenceCall,
  posName,
  isSubmitting,
}: {
  setOpenRequestPolicePresencePopup: (
    value: OpenRequestPolicePresencePopup,
  ) => void;
  requestPolicePresenceCall: (data: {
    reasonValue: PolicePresenceReason;
    reasonLabel: string;
    furtherReasoning: string;
  }) => void;
  posName?: string;
  isSubmitting: boolean;
}) {
  const [selectedReason, setSelectedReason] = useState<{
    value: PolicePresenceReason;
    label: string;
  } | null>(null);
  const [furtherReasoning, setFurtherReasoning] = useState<string>("");
  const [errors, setErrors] = useState<{
    reason?: string;
    furtherReasoning?: string;
  }>({});
  const { t } = useTranslation();

  const reasonOptions = [
    {
      value: "notEnoughStaff",
      label: t(
        "pages.policeIncidentFormPage.ongoingPolicePresenceDialog.dropdown.options.notEnoughStaff",
      ),
    },
    {
      value: "transfer",
      label: t(
        "pages.policeIncidentFormPage.ongoingPolicePresenceDialog.dropdown.options.transfer",
      ),
    },
    {
      value: "other",
      label: t(
        "pages.policeIncidentFormPage.ongoingPolicePresenceDialog.dropdown.options.other",
      ),
    },
  ];

  const handleSubmit = () => {
    if (!selectedReason?.value) {
      setErrors({
        reason: t(
          "pages.policeIncidentFormPage.ongoingPolicePresenceDialog.dropdown.errors.reasonError",
        ),
      });
      return;
    }

    if (!furtherReasoning.trim()) {
      setErrors({
        furtherReasoning: t(
          "pages.policeIncidentFormPage.ongoingPolicePresenceDialog.dropdown.errors.otherReasonError",
        ),
      });
      return;
    }

    const payload = {
      reasonValue: selectedReason.value,
      reasonLabel: selectedReason.label,
      furtherReasoning: furtherReasoning,
    };

    requestPolicePresenceCall(payload);
  };

  const isConfirmDisabled =
    isSubmitting || !selectedReason || !furtherReasoning.trim();

  return (
    <PopupDialog
      open={true}
      onClose={() => setOpenRequestPolicePresencePopup(null)}
    >
      <PopupDialogTitle
        titleText={t(
          "pages.policeIncidentFormPage.ongoingPolicePresenceDialog.title",
          { name: posName ?? t("common.unknown") },
        )}
        closeDialog={() => setOpenRequestPolicePresencePopup(null)}
      />
      <Box
        sx={{ display: "flex", p: 2, flexDirection: "column", gap: "1.5rem" }}
      >
        <Dropdown
          label={t(
            "pages.policeIncidentFormPage.ongoingPolicePresenceDialog.dropdown.title",
          )}
          name="reason"
          values={reasonOptions}
          selectedValue={selectedReason?.value || ""}
          onChange={(value) => {
            const selectedOption = reasonOptions.find(
              (option) => option.value === value,
            );
            setSelectedReason(
              selectedOption as {
                value: PolicePresenceReason;
                label: string;
              } | null,
            );
            setErrors((prev) => ({ ...prev, reason: undefined }));
          }}
          errorMessage={errors.reason}
          placeholder={t(
            "pages.policeIncidentFormPage.ongoingPolicePresenceDialog.dropdown.placeholder",
          )}
        />
        <Typography>
          {t(
            "pages.policeIncidentFormPage.ongoingPolicePresenceDialog.provideFurtherExplanation",
          )}
        </Typography>
        <TextField
          fullWidth
          label={t(
            "pages.policeIncidentFormPage.ongoingPolicePresenceDialog.dropdown.otherReasonLabel",
          )}
          value={furtherReasoning}
          minRows={4}
          multiline
          onChange={(e) => {
            setFurtherReasoning(e.target.value);
            setErrors((prev) => ({ ...prev, furtherReasoning: undefined }));
          }}
          error={!!errors.furtherReasoning}
          helperText={errors.furtherReasoning}
        />
        <Banner
          bannerType={BannerList.WARNING}
          body={[
            t(
              "pages.policeIncidentFormPage.ongoingPolicePresenceDialog.warningBannerBody1",
            ),
            t(
              "pages.policeIncidentFormPage.ongoingPolicePresenceDialog.warningBannerBody2",
            ),
          ]}
        />
        <Box
          sx={{
            marginTop: "2em",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button
            label={t("buttonLabels.close")}
            testId="close-button"
            onClick={() => setOpenRequestPolicePresencePopup(null)}
            variant="outlined"
          />
          <Button
            label={t("buttonLabels.confirm")}
            testId="confirm-button"
            type="submit"
            disabled={isConfirmDisabled}
            onClick={handleSubmit}
          />
        </Box>
      </Box>
    </PopupDialog>
  );
}
